import React, { Fragment, lazy, Suspense, useContext } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
import ConnectingLoader from 'src/assets/images/common/connecting.svg';
import Styles from 'src/components/themeStyle';

import AuthGuard from './components/Auth/AuthGuard';
import GuestGuard from './components/Auth/GuestGuard';
import { BASE_URL } from './config/constant';
import AdminLayout from './layouts/AdminLayout';
import AuthLayout from './layouts/AuthLayout';
import { ThemeContext } from './providers/themeProvider';

const StyleTag = () => {
  const [themeMode, setTheme] = useContext(ThemeContext);
  console.log(themeMode.theme);
  return (
    <div>
      <style>{Styles(themeMode.theme)}</style>
    </div>
  );
};

export const DoLoader = () => {
  return (
    <section className="h-screen d-flex align-items-center justify-content-center f-18 f-w-500 text-dark bg-white">
      <img src={ConnectingLoader} alt="Connect" className="wid-40" />
      <StyleTag />
    </section>
  );
};

const User = JSON.parse(localStorage.getItem('User'));

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<DoLoader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/signin',
    component: lazy(() => import('./views/auth/signin/SignIn'))
  },
  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   path: '/auth/signup',
  //   component: lazy(() => import('./views/auth/register/index'))
  // },
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/register',
    component: lazy(() => import('./views/auth/register/index'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/book-demo',
    component: lazy(() => import('./views/auth/book-demo/index'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/forgot-password',
    component: lazy(() => import('./views/auth/forgot-password/ForgotPassword'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/auth/change-password',
    component: lazy(() => import('./views/auth/change-password/ChangePassword'))
  },
  // {
  //   exact: true,
  //   layout: AuthLayout,
  //   guard: GuestGuard,
  //   path: '/',
  //   component: lazy(() => import('./views/landing')),
  // },
  // {
  //   exact: true,
  //   // layout: AuthLayout,
  //   guard: GuestGuard,
  //   path: '/landing',
  //   component: lazy(() => import('./views/landing-v2'))
  // },
  {
    exact: true,
    guard: GuestGuard,
    path: '/',
    component: lazy(() => import('./views/landing-v3'))
  },
  {
    exact: true,
    path: '/blogs',
    component: lazy(() => import('./views/landing-v3/blogs'))
  },
  {
    exact: true,
    path: '/blogs/:blog_title',
    component: lazy(() => import('./views/landing-v3/blogs/details'))
  },
  {
    exact: true,
    // layout: AuthLayout,
    guard: GuestGuard,
    path: '/contact',
    component: lazy(() => import('./views/contact'))
  },
  // {
  //   exact: true,
  //   path: '/auth/signup',
  //   component: lazy(() => import('./views/auth/signup/SignUp'))
  // },
  {
    exact: true,
    path: '/auth/otp',
    component: lazy(() => import('./views/auth/OtpVerification'))
  },
  {
    exact: true,
    layout: AuthLayout,
    path: '/auth/terms',
    component: lazy(() => import('./views/auth/Terms'))
  },
  {
    exact: true,
    layout: AuthLayout,
    path: '/auth/support',
    component: lazy(() => import('./views/auth/Support'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/errors/NotFound404'))
  },
  {
    exact: true,
    path: '/maintenance/coming-soon',
    component: lazy(() => import('./views/maintenance/ComingSoon'))
  },
  {
    exact: true,
    path: '/maintenance/error',
    component: lazy(() => import('./views/maintenance/Error'))
  },
  {
    exact: true,
    path: '/maintenance/offline-ui',
    component: lazy(() => import('./views/maintenance/OfflineUI'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/account-settings-new',
    component: lazy(() => import('./views/pages/accountSettings/index'))
  },
  {
    path: '*',
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/plan-expired/:provider',
        component: lazy(() => import('./views/upgrade/index'))
      },
      // {
      //   exact: true,
      //   path: '/targeting-summary',
      //   component: () => <Redirect to={process.env.REACT_APP_ACCOUNT_SUMMARY_ENABLE === '1' ? '/summary/account' : '/summary/all-campaigns'} />
      // },
      // {
      //   exact: true,
      //   path: '/targeting-summary-new',
      //   component: lazy(() => import('./views/pages/targettingSummaryNew/index'))
      // },
      // {
      //   exact: true,
      //   path: '/targeting-summary-new',
      //   component: () => <Redirect to={`/summary/all-campaigns`} />
      // },
      {
        exact: true,
        path: '/summary-old/:report_id',
        component: lazy(() => import('./views/pages/dashboards/index'))
      },
      {
        exact: true,
        path: '/summary-new-1/:report_id',
        component: lazy(() => import('./views/pages/dashboards/index'))
      },
      {
        exact: true,
        path: '/myntra-categorization',
        component: lazy(() => import('./views/pages/re-design/categorization-new/index'))
      },
      {
        exact: true,
        path: '/blinkit-categorization',
        component: lazy(() => import('./views/pages/re-design/categorization-new/index'))
      },
      {
        exact: true,
        path: '/flipkart-categorization',
        component: lazy(() => import('./views/pages/re-design/categorization-new/index'))
      },
      {
        exact: true,
        path: '/instamart-categorization',
        component: lazy(() => import('./views/pages/re-design/categorization-new/index'))
      },
      {
        exact: true,
        path: '/amazon-categorization',
        component: lazy(() => import('./views/pages/re-design/categorization-new/index'))
      },
      // {
      //   exact: true,
      //   path: '/categorization-ip',
      //   component: lazy(() => import('./views/pages/categorization-new/index'))
      // },
      {
        exact: true,
        path: '/categorization-old',
        component: lazy(() => import('./views/pages/categorization-new/index'))
      },
      {
        exact: true,
        path: '/heat-map',
        component: lazy(() => import('./views/pages/heatMap/index'))
      },
      {
        exact: true,
        path: '/keyword-harvesting',
        component: lazy(() => import('./views/pages/keywordHarvesting/index'))
      },
      {
        exact: true,
        path: '/brand-view-sqp-report',
        component: lazy(() => import('./views/pages/BrandView/index'))
      },
      {
        exact: true,
        path: '/asin-view-sqp-report',
        component: lazy(() => import('./views/pages/AsinView/index'))
      },
      {
        exact: true,
        path: '/scp-view-sqp-report',
        component: lazy(() => import('./views/pages/SCPView/index'))
      },
      {
        exact: true,
        path: '/text-categorization-old',
        component: lazy(() => import('./views/pages/re-design/brand-categorization/index'))
      },
      {
        exact: true,
        path: '/search-term',
        component: lazy(() => import('./views/pages/search-term/SearchTerm'))
      },
      {
        exact: true,
        path: '/seller-sales',
        component: lazy(() => import('./views/pages/sellerSales/index'))
      },
      // {
      //   exact: true,
      //   path: '/snapshot',
      //   component: lazy(() => import('./views/pages/snapshot/index'))
      // },
      {
        exact: true,
        path: '/amazon-snapshot',
        component: lazy(() => import('./views/pages/snapshot_new/index'))
      },
      {
        exact: true,
        path: '/flipkart-snapshot',
        component: lazy(() => import('./views/pages/snapshot_new/fk-snapshot'))
      },
      {
        exact: true,
        path: '/seller-sales-new',
        component: lazy(() => import('./views/pages/seller-sales-new'))
      },
      {
        exact: true,
        path: '/amazon-master-overview',
        component: lazy(() => import('./views/pages/master-overview/index'))
      },
      {
        exact: true,
        path: '/flipkart-master-overview',
        component: lazy(() => import('./views/pages/master-overview/fk-master-overview'))
      },
      {
        path: '/amazon-dayparting-performance',
        exact: true,
        component: lazy(() => import('./views/pages/dayparting/index'))
      },
      {
        path: '/amazon-bid-scheduler',
        exact: true,
        component: lazy(() => import('./views/pages/bid-scheduler/index'))
      },
      {
        path: '/amazon-dayparting-scheduler',
        exact: true,
        component: lazy(() => import('./views/pages/dayparting/dayparting-schedular/index'))
      },
      {
        path: '/amazon-dayparting-history',
        exact: true,
        component: lazy(() => import('./views/pages/dayparting/dayparting-history/index'))
      },
      {
        path: '/amazon-dayparting-schedule-log',
        exact: true,
        component: lazy(() => import('./views/pages/dayparting/dayparting-history/schedule-log'))
      },
      {
        path: '/dayparting-scheduler',
        exact: true,
        component: lazy(() => import('./views/pages/bid-scheduler/Dayparting-schedular/index'))
      },
      {
        exact: true,
        path: '/amazon-user-management',
        component: lazy(() => import('./views/pages/user-management/index'))
      },
      {
        exact: true,
        path: '/flipkart-user-management',
        component: lazy(() => import('./views/pages/user-management/index'))
      },
      {
        exact: true,
        path: '/instamart-user-management',
        component: lazy(() => import('./views/pages/user-management/index'))
      },
      {
        exact: true,
        path: '/myntra-user-management',
        component: lazy(() => import('./views/pages/user-management/index'))
      },
      {
        exact: true,
        path: '/blinkit-user-management',
        component: lazy(() => import('./views/pages/user-management/index'))
      },
      {
        exact: true,
        path: '/thankyou',
        component: lazy(() => import('./views/pages/thankyou/index'))
      },
      {
        exact: true,
        path: '/profile-old',
        component: lazy(() => import('./views/pages/profile/Profile'))
      },
      {
        exact: true,
        path: '/profile',
        component: lazy(() => import('./views/pages/profile-new/AccountManager/index'))
      },

      // {
      //   exact: true,
      //   path: '/add-profile',
      //   component: lazy(() => import('./views/pages/profile/AddAccount/SaveProfile'))
      // },
      {
        exact: true,
        path: '/users',
        component: lazy(() => import('./views/pages/users/Users'))
      },
      {
        exact: true,
        path: '/addaccount',
        // component: lazy(() => import('./views/pages/profile/AddAccount/AddAccount'))
        component: lazy(() => import('./views/pages/profile-new/AddAccount/index'))
      },
      {
        exact: true,
        path: '/aws/callback',
        component: lazy(() => import('src/components/Loader/FallbackLoader'))
      },
      {
        exact: true,
        path: '/edituser',
        component: lazy(() => import('./views/pages/users/edituser/edituser'))
      },
      {
        exact: true,
        path: '/charts',
        component: lazy(() => import('./views/pages/dashboards/static-index'))
      },
      {
        exact: true,
        path: '/api-testing',
        component: lazy(() => import('./testing/index'))
      },
      {
        exact: true,
        path: '/user-activity/dashboard',
        component: lazy(() => import('./views/pages/admin-dashboard/index'))
      },
      {
        exact: true,
        path: '/flipkart-dashboard-old',
        component: lazy(() => import('./views/pages/dashboards/flipkart-dashboard'))
      },
      {
        exact: true,
        path: '/flipkart-dashboard',
        component: () => <Redirect to="/summary/flipkart" />
      },
      {
        path: '/user-activity/timespend',
        component: lazy(() => import('./views/pages/TimeSpendBy/timespend'))
      },
      {
        exact: true,
        path: '/user-activity/clients',
        component: lazy(() => import('./views/pages/ClientList/index'))
      },
      {
        exact: true,
        path: '/billing',
        component: lazy(() => import('./views/pages/billing/index'))
      },
      {
        exact: true,
        path: '/amazon-hector-sov',
        component: lazy(() => import('./views/pages/sov/index'))
      },
      {
        exact: true,
        path: '/account-settings',
        component: lazy(() => import('./views/pages/accountSettings/index'))
      },
      {
        exact: true,
        path: '/edit-profile',
        component: lazy(() => import('./views/pages/profiles/index'))
      },
      {
        exact: true,
        path: '/account-settings/:is_upgrade/:for_upgrade',
        component: lazy(() => import('./views/pages/accountSettings/index'))
      },
      {
        exact: true,
        path: '/payment/:status',
        component: lazy(() => import('./views/pages/accountSettings/status'))
      },
      {
        exact: true,
        path: '/targeting-360',
        component: lazy(() => import('./views/pages/targetting/index'))
      },
      {
        exact: true,
        path: '/myntra/text-categorization',
        component: lazy(() => import('src/views/pages/text-categorization/index'))
      },
      {
        exact: true,
        path: '/blinkit/text-categorization',
        component: lazy(() => import('src/views/pages/text-categorization/index'))
      },
      {
        exact: true,
        path: '/summary/:report_id',
        // component: lazy(() => import('./views/pages/dashboards/index'))
        component: lazy(() => import('./views/pages/targetting/report-dashboard'))
      },
      {
        exact: true,
        path: '/amazon/:report_id',
        component: lazy(() => import('./views/pages/targetting/report-dashboard'))
      },
      {
        exact: true,
        path: '/flipkart-text-categorization',
        component: lazy(() => import('src/views/pages/text-categorization/index'))
      },
      {
        exact: true,
        path: '/instamart-text-categorization',
        component: lazy(() => import('src/views/pages/text-categorization/index'))
      },
      {
        exact: true,
        path: '/amazon-text-categorization',
        component: lazy(() => import('src/views/pages/text-categorization/index'))
      },
      {
        exact: true,
        path: '/summary/:report_id/:keywordId/:keywordText',
        // component: lazy(() => import('./views/pages/dashboards/index'))
        component: lazy(() => import('./views/pages/targetting/report-dashboard'))
      },
      {
        exact: true,
        path: '/account-management',
        component: lazy(() => import('./views/pages/profile-new/AccountManagement/index'))
      },
      {
        exact: true,
        path: '/add-profile',
        component: lazy(() => import('./views/pages/profile-new/SelectProfile/index'))
      },
      {
        exact: true,
        path: '/account-manager',
        component: lazy(() => import('./views/pages/profile-new/AccountManager/index'))
      },
      {
        exact: true,
        path: '/rules',
        component: lazy(() => import('./views/pages/rules/index'))
      },
      {
        exact: true,
        path: '/rules-new',
        component: lazy(() => import('./views/pages/rules-new/index'))
      },
      {
        exact: true,
        path: '/blacklist',
        component: lazy(() => import('./views/pages/Blacklist/index'))
      },
      {
        exact: true,
        path: '/custom/payments',
        component: lazy(() => import('./views/pages/Custom-Payments/index'))
      },
      {
        exact: true,
        path: '/cpc/history',
        component: lazy(() => import('./views/pages/History/index'))
      },
      {
        exact: true,
        path: '/admin/client-access-details',
        component: lazy(() => import('./views/super-admin/client-access-details'))
      },
      // {
      //   exact: true,
      //   path: '/admin/coupons',
      //   component: lazy(() => import('./views/pages/coupons/index'))
      // },
      {
        exact: true,
        path: '/admin/coupons',
        component: lazy(() => import('./views/pages/Custom-Coupons/index'))
      },
      {
        exact: true,
        path: '/admin/useraccountdetails',
        component: lazy(() => import('./views/pages/user-account-details/index'))
      },
      {
        exact: true,
        path: '/amazon-audience-report',
        component: lazy(() => import('./views/pages/audience-report/index'))
      },
      {
        exact: true,
        path: '/amazon-asin-audience-report',
        component: lazy(() => import('./views/pages/asin-audience-report/index'))
      },
      {
        exact: true,
        path: '/settings/amazon',
        component: lazy(() => import('./views/pages/settings/index'))
      },
      {
        exact: true,
        path: '/amazon-blacklist-keywords',
        component: lazy(() => import('./views/super-admin/blacklist-rule/index'))
      },
      {
        exact: true,
        path: '/amazon-chatgpt',
        component: lazy(() => import('./views/pages/chatgpt/index'))
      },
      {
        exact: true,
        path: '/amazon-create-audience',
        component: lazy(() => import('./views/pages/audience-report/create-audience'))
      },
      {
        exact: true,
        path:'/amazon-insights',
        component: lazy(()=> import('./views/pages/amc-insights/amc-insight-template'))
      },
      {
        exact: true,
        path:'/amazon-path-to-conversion',
        component: lazy(()=> import('./views/pages/amc-insights/amc-path-conversion'))
      },
      {
        exact: true,
        path: '/amazon-budget-rules-creation',
        component: lazy(() => import('./views/pages/rules-navigation/pages/index.js'))
      },
      {
        exact: true,
        path: '/amazon-automated-rules/:type_rule',
        component: lazy(() => import('./views/pages/rules-navigation/pages/rules'))
      },
      {
        exact: true,
        path: '/amazon-automated-rules/:type_rule/create',
        component: lazy(() => import('./views/pages/rules-navigation/pages/main'))
      },
      {
        exact: true,
        path: '/amazon-automated-rules/:type_rule/details/:type_rule_id',
        component: lazy(() => import('./views/pages/rules-navigation/pages/main'))
      },
      {
        path: '*',
        exact: true,
        component: () => <Redirect to={BASE_URL} />
      }
    ]
  }
];

export default routes;
