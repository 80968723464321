import React, { useContext, useEffect, useState } from 'react';

import { useCommonContext } from 'src/contexts/CommonContext';

import { ConfigContext } from '../../../contexts/ConfigContext';
import useWindowSize from '../../../hooks/useWindowSize';
import NavContent from './NavContent';
import NavLogo from './NavLogo';
import useMenuItems from './useNewMenuItems';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import navigation from '../../../new-menu-items';
import { ReactComponent as HectorLogo } from 'src/assets/images/common/logo-new.svg';
import { ReactComponent as HectorLogoFull } from 'src/assets/images/new-logo-contact.svg';
import { ReactComponent as LockIcon } from 'src/assets/images/common/circle_lock.svg';
import { ReactComponent as UnlockIcon } from 'src/assets/images/common/circle_unlock.svg';
import { Link, useLocation } from 'react-router-dom';
import { ThemeContext } from 'src/providers/themeProvider';

const Navigation = ({ IsFlipkartUser = 0, isTrail = false }) => {
  const configContext = useContext(ConfigContext);
  const {
    layout,
    layoutType,
    navFixedLayout,
    collapseMenu,
    rtlLayout,
    boxLayout,
    subLayout,
    navBackColor,
    navDropdownIcon,
    navBrandColor,
    navListIcon,
    navActiveListColor,
    navListTitleColor,
    navBackImage,
    navIconColor,
    navListTitleHide,
    layout6Background,
    layout6BackSize
  } = configContext.state;
  const windowSize = useWindowSize();

  const { commonState, commonDispatch } = useCommonContext();
  const [collapsed, setCollapsed] = useState(true);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const location = useLocation();
  const theme = useContext(ThemeContext);

  // const scroll = () => {
  //     if (navFixedLayout && headerFixedLayout === false) {
  //         const main = document.querySelector('.pcoded-navbar');
  //         const el = document.querySelector('.pcoded-navbar.menupos-fixed');
  //         const scrollPosition = window.pageYOffset;
  //         if (scrollPosition > 60) {
  //             el.style.position = 'fixed';
  //             el.style.transition = 'none';
  //             el.style.marginTop = '0';
  //         } else {
  //             main.style.position = 'absolute';
  //             main.style.marginTop = '56px';
  //         }
  //     } else {
  //         document.querySelector('.pcoded-navbar').removeAttribute('style');
  //     }
  // };

  let navClass = [
    'pcoded-navbar'
    //layoutType
  ];

  if (subLayout !== null && subLayout !== '' && subLayout !== 'layout-6' && subLayout !== 'layout-8' && subLayout !== 'horizontal-2') {
    navClass = [...navClass, subLayout];
  } else {
    navClass = [
      ...navClass,
      layoutType,
      navBackColor,
      navBrandColor,
      'drp-icon-' + navDropdownIcon,
      'menu-item-icon-' + navListIcon,
      navActiveListColor,
      navListTitleColor
    ];

    if (navBackImage) {
      navClass = [...navClass, navBackImage];
    }

    if (navIconColor) {
      navClass = [...navClass, 'icon-colored'];
    }

    if (!navFixedLayout && layout !== 'horizontal') {
      navClass = [...navClass, 'menupos-static'];
    }

    if (navListTitleHide) {
      navClass = [...navClass, 'caption-hide'];
    }
  }

  if (layout === 'horizontal') {
    navClass = [...navClass, 'theme-horizontal'];
  }

  if (windowSize.width < 992 && !collapseMenu) {
    navClass = [...navClass, 'mob-open'];
  } else if (collapseMenu) {
    navClass = [...navClass, 'navbar-collapsed'];
  }

  if (subLayout === 'layout-6') {
    document.body.classList.add('layout-6');
    document.body.style.backgroundImage = layout6Background;
    document.body.style.backgroundSize = layout6BackSize;
  }

  if (subLayout === 'layout-8') {
    document.body.classList.add('layout-8');
  }

  if (layoutType === 'dark') {
    document.body.classList.add('datta-dark');
  } else {
    document.body.classList.remove('datta-dark');
  }

  if (rtlLayout) {
    document.body.classList.add('datta-rtl');
  } else {
    document.body.classList.remove('datta-rtl');
  }

  if (boxLayout) {
    document.body.classList.add('container');
    document.body.classList.add('box-layout');
  } else {
    document.body.classList.remove('container');
    document.body.classList.remove('box-layout');
  }

  let navStyle;

  let navBarClass = ['navbar-wrapper'];
  if (layout === 'horizontal' && subLayout === 'horizontal-2') {
    navBarClass = [...navBarClass, 'container'];
  }

  const [customMenuItems, setCustomMenuItems] = useState([]);

  const { menuItems } = useMenuItems();

  useEffect(() => {
    setCustomMenuItems(menuItems);
  }, [menuItems]);

  let navContent = (
    <div className={navBarClass.join(' ')}>
      <NavLogo />
      <NavContent navigation={customMenuItems} />
    </div>
  );
  if (windowSize.width < 992) {
    navContent = (
      <div className="navbar-wrapper">
        <NavLogo />
        <NavContent navigation={customMenuItems} />
      </div>
    );
  }

  if (isTrail == true) navClass = navClass.concat(['custom-top-51 billing_notify']);

  if (commonState.lockStatus === 1) {
    navClass = navClass.concat(['default-wid']);
  } else if (commonState.lockStatus === 0) {
    navClass = navClass.filter((itemClass) => itemClass !== 'default-wid');
  }

  return (
    <React.Fragment>
    {/* {process.env.REACT_APP_ADMIN_ACCESS_EMAIL.indexOf(commonState?.userInfo?.email) > -1  ?  */}
    <div className='new-theme-sidemenu'>
    <Sidebar collapsed={collapsed} backgroundColor={theme[0].theme === 'DARK' ? "#333b45" : "#130c42"} width='300px' transitionDuration={1000}>
      <div className='main-logo-container'>
        {collapsed ? <HectorLogo /> : <HectorLogoFull width="150" />}
      </div>
      <Menu
      closeOnClick
        menuItemStyles={{
        button: ({ active }) => {
          const baseStyle = {
            color: active
              ? '#fff'
              : '#fff',
            backgroundColor: active
              ? theme[0].theme === 'DARK' ? '#1f2934' : '#342785'
              : '',
            margin: '6px 16px',
            borderRadius: '6px',
            height: "auto",
            padding: '5px 8px 5px 7px',
            fontSize: "10px",
            lineHeight: "normal",
          };
          return {
            ...baseStyle,
            "&:hover": {
              backgroundColor: theme[0].theme === 'DARK' ? '#1f2934' : '#342785',
              color: '#fff',
            },
          };
        }
      }}>
      {menuItems.map((submenu, index) => (
        <SubMenu 
        active={activeSubmenu === submenu.title || window.location.href.indexOf(String(submenu.title).toLowerCase()) > -1 || submenu.items.some(item => item.path === location.pathname)} 
        key={index} 
        label={submenu.title} 
        icon={submenu.icon}
        onClick={() => setActiveSubmenu(activeSubmenu === submenu.title ? null : submenu.title)}
        >
          {submenu.items.map((item, subIndex) => {
            if(item?.items?.length > 0) {
              return (
                <SubMenu 
                active={activeSubmenu === item.title || item.items.some(item => item.path === location.pathname)} 
                key={index} 
                label={item.title} 
                icon={item.icon}
                onClick={() => setActiveSubmenu(activeSubmenu === item.title ? null : item.title)}
                >
                  {item?.items?.map((childItem, subIndex) => {
                    return (
                        <MenuItem 
                        active={location.pathname === childItem.path} 
                        onClick={() => {
                          window.location.href = childItem.path;
                        }} 
                        key={subIndex} icon={childItem.icon}
                        >
                          {childItem.label}
                        </MenuItem>
                    )
                  })}
                </SubMenu>
              )
            }
            return (
                <MenuItem 
                active={location.pathname === item.path} 
                onClick={() => {
                  window.location.href = item.path;
                }} 
                key={subIndex} icon={item.icon}
                >
                  {item.label}
                </MenuItem>
            )
          })}
        </SubMenu>
      ))}

            <div className="main-logo-container toggle-btn">
              {collapsed ? <LockIcon onClick={() => setCollapsed(false)} /> : <UnlockIcon onClick={() => setCollapsed(true)} />}
            </div>
          </Menu>
        </Sidebar>
        {/* Overlay */}
        {collapsed === false && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              zIndex: -1,
              cursor: 'pointer',
              opacity: collapsed ? 0 : 1,
              transition: 'opacity 6s ease-in-out'
            }}
            onClick={() => setCollapsed(true)}
          ></div>
        )}
      </div>
      {/* //   :
  //   <nav className={navClass.join(' ')} style={{...navStyle}}>
  //   {navContent}
  // </nav>
  // } */}
    </React.Fragment>
  );
};

export default Navigation;
