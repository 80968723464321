const StatusRuleIcon = ({ fillColor = "black", ...rest }) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" { ...rest }>
        <path d="M1.11816 10.182H27.0273M15.4363 15.6365H20.8909M15.4363 21.0911H18.1636M7.25453 6.09109H7.2668M12.7091 6.09109H12.7213M9.9818 10.182V27.2275M1.11816 14.2729C1.11816 8.16654 1.11816 5.112 3.01498 3.21518C4.9118 1.31836 7.96498 1.31836 14.0727 1.31836C20.1791 1.31836 23.2336 1.31836 25.1304 3.21518C27.0273 5.112 27.0273 8.16518 27.0273 14.2729C27.0273 20.3793 27.0273 23.4338 25.1304 25.3306C23.2336 27.2274 20.1804 27.2275 14.0727 27.2275C7.96635 27.2275 4.9118 27.2274 3.01498 25.3306C1.11816 23.4338 1.11816 20.3806 1.11816 14.2729Z" stroke={fillColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>        
    );
};

export default StatusRuleIcon;

