import { ReactComponent as SnapshotIcon } from 'src/assets/images/common/snapshot-white.svg';
import { ReactComponent as FlipkartIcon } from 'src/assets/images/common/flipkart-white.svg';
import { ReactComponent as AmazonIcon } from 'src/assets/images/common/amazon-white.svg';
import { ReactComponent as MasterOverviewIcon } from 'src/assets/images/common/master-overview.svg';
import { ReactComponent as MyntraIcon } from 'src/assets/images/myntra-white.svg';
import { ReactComponent as SettingsIcon } from 'src/assets/images/common/settings-white.svg';
import { ReactComponent as DaypartIconIcon } from 'src/assets/images/common/daypart.svg';
import { ReactComponent as UserActivityIcon } from 'src/assets/images/common/harvesting.svg';
import { ReactComponent as TimeSpendIcon } from 'src/assets/images/common/user-time-spend.svg';
import { ReactComponent as ClientListIcon } from 'src/assets/images/common/client-list.svg';
import { ReactComponent as UserAccountDetailsIcon } from 'src/assets/images/common/user-account.svg';
import { ReactComponent as CoupenIcons } from 'src/assets/images/common/coupen-dashboard.svg';
import { ReactComponent as CustomPaymentsIcon } from 'src/assets/images/common/seller-sales.svg';
import { ReactComponent as ClientAccessIcon } from 'src/assets/images/common/user-input-menu.svg';
import { ReactComponent as BlinkitIcon } from 'src/assets/images/common/blinkit-single.svg';
import { ReactComponent as DashboardIcon } from 'src/assets/images/common/ams-dash.svg';
import { ReactComponent as HectorGptIcon } from 'src/assets/images/common/chat.svg';
import { ReactComponent as InstamartIcon } from 'src/assets/images/common/instamart.svg';

const newMenuItems = [
  {
    title: 'Amazon',
    icon: <AmazonIcon />,
    items: [
      { 
        label: 'Targeting 360', 
        icon: <DashboardIcon />, 
        path: '/amazon/targeting' 
      },
      { 
        label: 'Snapshot', 
        icon: <SnapshotIcon />, 
        path: '/amazon-snapshot' 
      },
      { 
        label: 'Master Overview', 
        icon: <MasterOverviewIcon />, 
        path: '/amazon-master-overview' 
      },
      {
        label: 'Dayparting',
        icon: <DaypartIconIcon />,
        path: '/amazon-dayparting-performance'
      },
    ]
  },
  {
    title: 'Flipkart',
    icon: <FlipkartIcon />,
    items: [
      { 
        label: 'Targeting 360', 
        icon: <DashboardIcon />, 
        path: '/summary/flipkart' 
      },
      { 
        label: 'Snapshot', 
        icon: <SnapshotIcon />, 
        path: '/flipkart-snapshot' 
      },
      { 
        label: 'Master Overview', 
        icon: <MasterOverviewIcon />, 
        path: '/flipkart-master-overview' 
      }
    ]
  },
  {
    title: 'Myntra',
    icon: <MyntraIcon />,
    items: [
      { 
        label: 'Targeting 360', 
        icon: <DashboardIcon />, 
        path: '/summary/myntra' 
      },
      // { 
      //   label: 'Snapshot', 
      //   icon: <SnapshotIcon />, 
      //   path: '/myntra-snapshot' 
      // },
      // { 
      //   label: 'Master Overview', 
      //   icon: <MasterOverviewIcon />, 
      //   path: '/myntra-master-overview' 
      // }
    ]
  },
  {
    title: 'Blinkit',
    icon: <BlinkitIcon />,
    items: [
      { 
        label: 'Targeting 360', 
        icon: <DashboardIcon />, 
        path: '/summary/blinkit' 
      },
      // { 
      //   label: 'Snapshot', 
      //   icon: <SnapshotIcon />, 
      //   path: '/myntra-snapshot' 
      // },
      // { 
      //   label: 'Master Overview', 
      //   icon: <MasterOverviewIcon />, 
      //   path: '/myntra-master-overview' 
      // }
    ]
  },
  {
    title: 'Instamart',
    icon: <InstamartIcon />,
    items: [
      { 
        label: 'Targeting 360', 
        icon: <DashboardIcon />, 
        path: '/summary/instamart' 
      },
      // { 
      //   label: 'Snapshot', 
      //   icon: <SnapshotIcon />, 
      //   path: '/myntra-snapshot' 
      // },
      // { 
      //   label: 'Master Overview', 
      //   icon: <MasterOverviewIcon />, 
      //   path: '/myntra-master-overview' 
      // }
    ]
  },
  {
    title: 'Settings',
    icon: <SettingsIcon />,
    items: [
      {
        label: 'User Activity Dashboard',
        icon: <UserActivityIcon />,
        path: '/user-activity/dashboard'
      },
      {
        label: 'User Activity Timespend',
        icon: <TimeSpendIcon />,
        path: '/user-activity/timespend'
      },
      {
        label: 'User Clients Dashboard',
        icon: <ClientListIcon />,
        path: '/user-activity/clients'
      },
      {
        label: 'User Account Details',
        icon: <UserAccountDetailsIcon />,
        path: '/admin/useraccountdetails'
      },
      {
        label: 'Coupons',
        icon: <CoupenIcons />,
        path: '/admin/coupons'
      },
      {
        label: 'Custom Payments',
        icon: <CustomPaymentsIcon />,
        path: '/custom/payments'
      },
      {
        label: 'Custom Access Details',
        icon: <ClientAccessIcon />,
        path: '/admin/client-access-details'
      },
      // {
      //   label: 'Hector GPT',
      //   icon: <HectorGptIcon />,
      //   path: '/amazon-chatgpt'
      // },
    ]
  }
]

export default newMenuItems;
