const BidRuleIcon = ({ fillColor = "black", ...rest }) => {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" { ...rest }>
            <path d="M17 20.1765L26.747 31.6192C27.057 31.98 27.4381 32.2729 27.8666 32.4796C28.295 32.6862 28.7615 32.8022 29.2369 32.8202C29.7122 32.8381 30.1861 32.7578 30.629 32.5841C31.0718 32.4104 31.474 32.1471 31.8104 31.8107C32.1467 31.4744 32.41 31.0722 32.5837 30.6293C32.7574 30.1865 32.8378 29.7126 32.8198 29.2372C32.8018 28.7619 32.6859 28.2954 32.4792 27.8669C32.2726 27.4385 31.9797 27.0573 31.6189 26.7473L20.1761 17.0003M1.16663 16.8499L10.817 26.5003M16.8495 1.16699L26.5 10.8174M18.056 2.37349L25.2935 9.61091C25.2935 9.61091 21.674 14.4369 18.056 18.0564C14.4365 21.6743 9.61055 25.2938 9.61055 25.2938L2.37313 18.0564C2.37313 18.0564 5.99263 13.2304 9.61055 9.61091C13.23 5.99299 18.056 2.37349 18.056 2.37349Z" stroke={fillColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default BidRuleIcon;

