import { THEME_TYPE } from 'src/store/theme.js';

const Theme = {
  [THEME_TYPE.LIGHT]: {
    background: '#fafafa',
    text: '#2F3133',
    divider: 'rgba(0, 0, 0, 0.12)',
    themeColor: '#fff',
    themeactive: '#fff',
    activeColor: '#fff',
    activetxt: '#284bde',
    themeicon: '#3F4D67',
    themecrd: '#f8f8f8',
    themelight: '#f2f5f9',
    tablehead: '#F4F6F8',
    tablesticky: '#29334B',
    tabledata: '#767980',
    chartdrop: '#f3f3f3',
    textblsh: '#3f4d67',
    disabledt: 'rgb(248, 248, 248)',
    checkbg: '#002a74',
    charthead: '#F4F6F9',
    navbar: '#130C42',
    activanav: '#001b8e',
    actsub: '#180E5F',
    catsec: '#f0f0f0',
    themeblue: '#284BDD',
    catactive: '#EBF5FE',
    overlay: 'rgba(242, 242, 245, 0.2)',
    trigcrd: '#f8f8f8',
    edittri: '#fff',
    upstairs: '#e7ffe7',
    downstairs: '#ffeaea',
    secondary: '#6c757d',
    tabborder: '#e5e4f6',
    dateborder: '#fff',
    boxshadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    bilshadow: '0px 20px 40px 3px rgba(202, 196, 196, 0.25)',
    conshadow: '0px 10px 60px 0px rgba(226, 236, 249, 0.5)',
    sovShadow: '0px 10px 60px 0px rgba(226, 236, 249, 0.5), 1px 4px 6px 0px rgba(0, 0, 0, 0.15) inset',
    viewinp: '#eeeeee',
    linktxt: '#5C59E8',
    proflnk: '#462da3',
    selcedopt: '#284bde0a',
    tablegrp: '#f9f9fb',
    territry: '#E9E9E9',
    dahtab: '#e9f0ff',
    progressbar: '#e8ebee',
    lightGray: '#f2f2f2',
    asinTable: '#d5e6fc',
    headTxt: '#333843',
    beforeCheck: '#fff',
    afterCheck: '#1877f2',
    tabLink: '#949191',
    rgbtxt: 'rgba(51, 51, 51, 0.85)',
    grayTxt: '#252631',
    asinCol: '#f8fafb',
    succBlk: '#e0faef',
    t2e2c34: '#2e2c34',
    f3f3f4: '#f3f3f4',
    f84818a: '#84818a',
    f333843: '#333843',
    tfoot: '#f7faff',
    tfoottxt: '#333843',
    tilestxt: '#525252',
    tartab: '#f0f1f3',
    navbor: '#dddfe1',
    hovetthm: '#f6f6f6',
    chart_x_axis_text: '#929199',
    drphov: '#edf5ff',
    bg_delta: '#eaedec',
    txt_delta: '#b5b1b1',
    f222E3E: '#222E3E',
    f344054: '#344054',
    f101828: '#101828',
    ff9fafb: '#f9fafb',
    f181a1c: '#181a1c',
    f7F56D9: '#7F56D9',
    f0a60cb: '#0a60cb',
    ff2f4f7: '#f2f4f7',
    f202226: '#202226',
    f3c3c3c: '#3c3c3c',
    black: '#000',
    fe7e6e6: '#e7e6e6',
    t101828: '#101828',
    t667085: '#667085',
    bf4f9ff: '#f4f9ff',
    gtxt: 'rgba(50, 55, 63, 0.87)',
    biltab: '#f9fafb',
    actrow: '#F0F6FE',
    grthem: '#fafafa',
    f141414: '#141414',
    fEFEAFD: '#EFEAFD',
    f5932ea: '#5932ea',
    f027a48: '#027a48',
    fecfdf3: '#ecfdf3',
    ff6f4ff: '#f6f4ff',
    fe1261a: '#e1261a',
    ffef0f0: '#fef0f0',
    f797979: '#797979',
    fe80089: '#e80089',
    fe8008933: '#e8008933',
    fc9c9cb: '#c9c9cb',
    grcrd: '#fafafa',
    bilact: '#f4f4f4',
    purbtnv2: '#7F56D9',
    snapshot_revenue_heading: '#333',
    snapshot_card_box_shadow: 'rgba(238, 238, 238, 0.5)',
    fECF4FE: '#ECF4FE',
    f737791: '#737791',
    feff5ff: '#eff5ff',
    fF6FAFF: '#F6FAFF',
    f7E7E7E: '#7E7E7E',
    secbg: '#FBFBFB',
    tabclr: '#4F4F4F',
    sovdrp: '#303030',
    hovthem: '#d8e4fe',
    snapshot_card_growth_status_low_background: 'rgba(243, 37, 59, 0.2)',
    snapshot_card_growth_status_high_background: '#e8f6ef',
    snapshot_card_growth_status_high_color: '#10B981',
    snapshot_card_growth_status_low_color: '#F3253B',
    snapshot_card_growth_status_ideal_color: '#000',
    snapshot_product_report_table__body_row_cell_background: '#fff',
    snapshot_product_report_background: '#FDFCFC',
    snapshot_product_report_table__header_row_cell_border: '#F0F1F3',
    snapshot_product_report_table__body_row_cell_color: '#000',
    snapshot_product_report_table__header_row_cell_prev_date_color: '#333843',
    snapshot_product_report_table__header_row_cell_product: '#FAF8F8',
    custom_react_table_cell_border: '#f0f1f3',
    performance_screen_product_report_row_card_main_list_item_title: '#8f9091',
    performance_screen_inventory_card_title: '#9d9d9d',
    trend_chart_border_color: '#f3f4f6',
    draggable_dropdown_checkbox: '#fff',
    cancel_btn: '#f2f2f2',
    mapped_dropdown_body_bg: '#fff',
    mapped_dropdown_body_item: '#1877f2',
    mapped_dropdown_body_title: '#f3f0ff',
    mapped_dropdown_border: '#a7a7a7',
    prodcrd: '#F7F7F7',
    progbar: '#F3F0FF',
    f223079: '#223079',
    f4F4F4F: '#4F4F4F',
    snapshot_card_growth_status_equal_background: 'rgb(254 215 170)',
    f8F8F91: '#8F8F91',
    snapshot_card_growth_status_equal_color: 'rgb(249 115 22)',
    snapshot_card_growth_status_low_purple_color: 'rgb(220 38 38)',
    snapshot_card_growth_status_low_purple_background: 'rgb(254 226 226)',
    f8F8F91: '#8F8F91',
    f303030: '#303030',
    f7E7E7E: '#7E7E7E',
    fD1D5DB: '#D1D5DB',
    acttab: '0px 6px 16px 0px rgba(117, 117, 117, 0.6)',
    b303030: '#303030',
    f8E8F96: '#8E8F96',
    mapped_dropdown_body_title: '#A100BD',
    mapped_dropdown_border: '#a7a7a7',
    fF9F7FC: '#F9F7FC',
    master_overview_divider: '#D9D9D9',
    trend_chart_border_color: '#f3f4f6',
    master_overview_product_report_product_title: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%)',
    f8F8F91: '#8F8F91',
    f8F8F91: '#8F8F91',
    snapshot_product_report_product_title: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%)',
    masterBorder: 'rgba(0, 0, 0, 0.10)',
    greenStatusBg: '#e7f4ee',
    greenStatusTxt: '#0d894f',
    redStatusBg: 'rgba(243, 37, 59, 0.2)',
    redStatusTxt: '#f3253b',
    fF7F7F7BF: '#F7F7F7BF',
    spBg: '#ede7ff',
    spText: '#6366F1',
    sbBg: '#e3ece6',
    sbText: '#228B22',
    sdBg: '#4d6a8726',
    sdText: '#4D6A87',
    eBg: '#ff99a045',
    eText: '#FF828B',
    pBg: '#b7f9ff99',
    pText: '#00919E',
    bBg: '#ead78e5c',
    bText: '#E2B300',
    f10182880: '#10182880',
    flagBorder: '#00000033',
    bgFCFCFC: '#FCFCFC',
    f5F6368CC: '#5F6368CC',
    grayTags: '#f2f2f2',
    heatmap_hover_shadow: 'rgba(255, 255, 255, 2)',
    bgA100BD1F: '#A100BD1F',
    boxBorder: '#E7E7E6',
    timeBadgeCard: '#F6F4FD',
    timeBadgeText: '#6366F1',
    f5F6368: '#5F6368',
    tabTextColour: '#32323299',
    metricsBorder: '#E8E8E8',
    activeCustomTabs: '#F4F4F7',
    bgF6F2FA99: '#F6F2FA99',
    f000000B2: '#000000B2',
    dayPartBorder: '#0000001A',
    rulesHeader: '#F9F9F9',
    descriptionText: '#636363',
    bE8E8E8: '#E8E8E8',
  },
  [THEME_TYPE.DARK]: {
    background: '#1D2025',
    text: '#fff',
    themeactive: '#333B45',
    divider: 'rgba(255, 255, 255, 0.12)',
    themeColor: '#1F2934',
    activeColor: '#1F2934',
    activetxt: '#fff',
    themeicon: '#fff',
    themecrd: '#1D2025',
    themelight: '#1F2934',
    tablehead: '#333B45',
    tablesticky: '#fff',
    tabledata: '#fff',
    chartdrop: '#333B45',
    textblsh: '#fff',
    disabledt: '#333B45',
    checkbg: '#fff',
    charthead: '#333B45',
    navbar: '#333B45',
    activanav: '#1D2025',
    actsub: '#1e2127',
    catsec: '#333B45',
    themeblue: '#fff',
    catactive: '#1D2025',
    overlay: '#333B45b0',
    trigcrd: '#333B45',
    edittri: '#1e1e1e',
    upstairs: '#333B45',
    downstairs: '#333B45',
    secondary: '#fff',
    tabborder: '#4d5969',
    dateborder: '#4d5969',
    boxshadow: 'rgb(0 0 0 / 20%) 0px 7px 29px 0px',
    bilshadow: 'rgb(0 0 0 / 20%) 0px 7px 29px 0px',
    sovShadow: 'rgb(0 0 0 / 20%) 0px 7px 29px 0px',
    conshadow: 'rgb(0 0 0 / 20%) 0px 7px 29px 0px',
    viewinp: '#333B45',
    linktxt: '#479eff',
    proflnk: '#fff',
    selcedopt: '#1D2025',
    tablegrp: '#333b45',
    territry: '#333b45',
    dahtab: '#333B45',
    progressbar: '#333B45',
    lightGray: '#333B45',
    asinTable: '#333B45',
    headTxt: '#fff',
    beforeCheck: '#333B45',
    afterCheck: '#1877f2',
    tabLink: '#fff',
    rgbtxt: '#fff',
    grayTxt: '#fff',
    asinCol: '#333B45',
    succBlk: '#333B45',
    t2e2c34: '#fff',
    f3f3f4: '#333B45',
    f84818a: '#fff',
    f333843: '#fff',
    tfoot: '#333B45',
    tfoottxt: '#fff',
    tilestxt: '#fff',
    tartab: '#4d5969',
    navbor: '#4d5969',
    hovetthm: '#333B45',
    chart_x_axis_text: '#929199',
    drphov: '#333B45',
    bg_delta: '#cdd0cf',
    txt_delta: '#646464',
    f222E3E: '#fff',
    f344054: '#fff',
    f101828: '#fff',
    ff9fafb: '#333B45',
    f181a1c: '#fff',
    f7F56D9: '#fff',
    f0a60cb: '#fff',
    ff2f4f7: '#333b45',
    f202226: '#fff',
    f3c3c3c: '#fff',
    black: '#fff',
    fe7e6e6: '#333B45',
    t101828: '#fff',
    t667085: '#fff',
    bf4f9ff: '#333b45',
    gtxt: '#fff',
    biltab: '#333b45',
    actrow: '#1E2025',
    grthem: '#333B45',
    f141414: '#fff',
    fEFEAFD: '#333B45',
    f5932ea: '#fff',
    f027a48: '#fff',
    fecfdf3: '#333B45',
    ff6f4ff: '#333B45',
    fe1261a: '#fff',
    ffef0f0: '#333B45',
    f797979: '#fff',
    fe80089: '#fff',
    fe8008933: '#333B45',
    fc9c9cb: '#333B45',
    grcrd: '#1F2934',
    bilact: '#1F2934',
    purbtnv2: '#333B45',
    snapshot_revenue_heading: '#fff',
    snapshot_card_box_shadow: 'rgba(29, 32, 37, 0.5)',
    fECF4FE: '#343b45',
    f737791: '#fff',
    feff5ff: '#343b45',
    fF6FAFF: '#1f2934',
    f7E7E7E: '#fff',
    secbg: '#333B45',
    tabclr: '#fff',
    sovdrp: '#fff',
    hovthem: '#1F2934',
    snapshot_revenue_title: 'rgb(241 245 249)',
    snapshot_card_header_tab: 'rgb(203 213 225)',
    snapshot_card_growth_status_low_background: 'rgb(127 29 29)',
    snapshot_card_growth_status_high_background: 'rgb(20 83 45)',
    snapshot_card_growth_status_high_color: 'rgb(187 247 208)',
    snapshot_card_growth_status_low_color: 'rgb(254 202 202)',
    snapshot_card_growth_status_ideal_background: 'rgb(51 65 85)',
    snapshot_card_growth_status_ideal_color: '#fff',
    snapshot_product_report_table__header_row_cell: 'rgba(203 213 225, 0.5)',
    snapshot_product_report_table__body_row_cell_background: '#1D2025',
    snapshot_product_report_background: '#1F2934',
    snapshot_product_report_table__header_row_cell_border: 'rgb(229 231 235)',
    snapshot_product_report_table__body_row_cell_color: '#fff',
    snapshot_product_report_table__header_row_cell_prev_date_color: '#fff',
    snapshot_product_report_table__header_row_cell_product: 'rgba(255,255,255,.1)',
    custom_react_table_cell_border: 'rgb(226 232 240)',
    performance_screen_product_report_row_card_main_list_item_title: 'rgb(226 232 240)',
    performance_screen_inventory_card_title: 'rgb(226 232 240)',
    trend_chart_border_color: '#4d5969',
    draggable_dropdown_checkbox: 'rgb(71 85 105)',
    cancel_btn: 'rgb(75 85 99)',
    mapped_dropdown_body_bg: 'rgb(51 65 85)',
    mapped_dropdown_body_item: '#fff',
    mapped_dropdown_body_title: 'rgb(71 85 105)',
    mapped_dropdown_border: '#4d5969',
    prodcrd: '#333B45',
    progbar: '#343b45',
    f223079: '#fff',
    f4F4F4F: '#fff',
    f8F8F91: '#fff',
    snapshot_card_growth_status_equal_background: 'rgb(249 115 22)',
    snapshot_card_growth_status_equal_color: 'rgb(254 215 170)',
    snapshot_card_growth_status_low_purple_background: 'rgb(127 29 29)',
    snapshot_card_growth_status_low_purple_color: 'rgb(254 202 202)',
    f8F8F91: '#fff',
    f303030: '#fff',
    f7E7E7E: '#fff',
    fD1D5DB: '#fff',
    acttab: 'rgb(0 0 0 / 20%) 0px 7px 29px 0px',
    b303030: '#333B45',
    f8E8F96: '#fff',
    fF9F7FC: '#343b45',
    master_overview_divider: '#4d5969',
    trend_chart_border_color: '#4d5969',
    master_overview_product_report_product_title: 'radial-gradient(at top right,rgba(31,41,52,1) 60%,rgba(32,44,51,0) 80%);',
    snapshot_product_report_product_title: 'linear-gradient(to left,rgba(29,32,37,1) 60%,rgba(32,44,51,0) 80%);',
    masterBorder: '#4d5969',
    greenStatusBg: '#0d894f',
    greenStatusTxt: '#e7f4ee',
    redStatusBg: '#f3253b',
    redStatusTxt: '#fff',
    fF7F7F7BF: '#1F2934',
    spBg: '#6366F1',
    spText: '#fff',
    sbBg: '#228B22',
    sbText: '#fff',
    sdBg: '#4D6A87',
    sdText: '#fff',
    eBg: '#FF828B',
    eText: '#fff',
    pBg: '#00919E',
    pText: '#fff',
    bBg: '#E2B300',
    bText: '#fff',
    f10182880: '#fff',
    flagBorder: '#4d5969',
    bgFCFCFC: 'rgb(51 65 85)',
    f5F6368CC: '#fff',
    grayTags: '#1f2934',
    heatmap_hover_shadow: 'rgba(29, 32, 37, 2)',
    bgA100BD1F: '#9d5df5',
    boxBorder: '#4d5969',
    timeBadgeCard: '#6366F1',
    timeBadgeText: '#F6F4FD',
    f5F6368: '#fff',
    tabTextColour: '#fff',
    metricsBorder: '#4d5969',
    activeCustomTabs: '#343b45',
    bgF6F2FA99: '#343b45',
    f000000B2: '#fff',
    dayPartBorder: '#4d5969',
    rulesHeader: '#343b45',
    descriptionText: '#fff',
    bE8E8E8: '#4d5969',
  }
};
//  , g.highcharts-axis.highcharts-yaxis text, g.highcharts-axis-labels.highcharts-yaxis-labels text
const Styles = (theme) => `
body, .fscreen-enbld, .trig-box, .tar_tab .nav-pills .nav-link.active:after, .tar_tab .nav-pills .show > .nav-link:after, .sticky_hder, .stic-crd, .master-overview-product-report-overlay, .srch_position, .performance-header-sticky {
  background-color: ${Theme[theme].background} !important;
  color: ${Theme[theme].text} !important;
}
.card, .bg-white, .themebg, rect.highcharts-background, .cat-search, .cat-options, .modal-body, .tsk-tsks, .rdrDefinedRangesWrapper, table thead tr:nth-child(1) th:nth-child(1) .form-group.check-box label:before, .cat-modal .form-group.check-box label:before, .modal-content, .cat-table table td, .custom-tooltip .tooltip-inner, .form-group.check-box.cursor-move label:before, .floating .item-drp, .tab-content, .selct_tils, .info_mod, .target_table td, .css-1nc9oow-DropDown, .react-dropdown-select-dropdown, .context-menu-card, .fsn-master-list .tab_top, .acc_bx,
.inp_slc_fld, .pro_bx, .fsn-master-list tbody tr td:nth-child(2)::after, .fsn-master-list tbody tr td:nth-child(1)::after, .feature_crd, .nav-pills, .acc-card_1, .acc-card_2, .fsn-master-list td:nth-child(2), .fsn-master-list td:nth-child(1), .fil_tag, .fsn-master-list.is-amazon td:nth-child(3), .master-overview-inventory-chart, .rules_body, .css-ds5dkt, .slct_prod, .set_brand .css-13cymwt-control, .set_brand .css-t3ipsp-control, .list-group-item, .rules_table.table thead tr th, .performance-main-line-chart, .performance-metric, .performance-heatmap, .performance-header,
.performance-heatmap-main table tr td:first-child, .performance-heatmap-main table tr th:first-child, .MuiPopover-paper, td.bsr_categorization_column, .daypart-history-listing-table td:nth-child(1), .daypart-history-listing-table td:nth-child(2), .rules-creation-panel-column, .rules-creation-panel-column-or-condition-section .rules-creation-panel-column-condition-section {
  background-color: ${Theme[theme].themeColor} !important;
  fill: ${Theme[theme].themeColor} !important;
}
.prod_prg_tip::before{
  border-top-color: ${Theme[theme].themeColor} !important;
}
.text-dark, .css-1dimb5e-singleValue, .css-tj5bde-Svg, .rdrDayNumber span, .list-show ol li, .highcharts-legend-item.highcharts-bar-series text, .ReactToast--close, .styles_selected_country__1EZ8f, .styles_dropdown_item_title__2_oyf, .styles_country_search__J15ie, .rdrWeekDays span, .css-8mmkcg, .heatmap-day-list li, .gray-box h2,
.performance-heatmap-main table tfoot tr:last-child td div, .heatmap-bulk-schedular-modal-gridbox-col h2 {
  color: ${Theme[theme].text} !important;
  fill: ${Theme[theme].text} !important;
}
p, .cat_rowfil, [type=radio]:not(:checked) + label, .trig-list, label, .or-crd .dropdown .selected-value input, .form-field, .dropdown .option, .form-control,
 .toggle-head .tog-thead, .rdrMonthName, .dropdown .selected-value input, [type=search], input, .rdrMonthAndYearPickers select, h4, .fa2-field input, ol.list-bg li, .acc-manager .nav-pills .nav-link,
 .highcharts-xaxis-labels span, .css-wsp0cs-MultiValueGeneric, .css-9jq23d, .css-9jq23d{
  color: ${Theme[theme].text} !important;
}
.highcharts-xaxis-labels span, .highcharts-axis.highcharts-xaxis {
  color: ${Theme[theme].chart_x_axis_text} !important;
  font-weight: 500 !important;
  margin-top: 5px !important;
}

g.highcharts-legend-item.highcharts-line-series text, g.highcharts-axis-labels.highcharts-xaxis-labels text {
  fill: ${Theme[theme].text} !important;
}
.my-tool, .tool-tab, .swal2-popup, .swal2-label, .swal2-radio, .swal2-checkbox, .custom-select, .form-control, 
.dropdown .selected-value input, [type=radio]:not(:checked) + label:before, .cat-brand, .cat-modal, .tsk-inp, .brand-centered, .add-footer, .css-1l64swt-DropDown, .or-crd .dropdown .selected-value input, .form-field, .dropdown .options, .fa2-field input, .modal-footer, .filter-chart, [type=radio]:checked + label:before, .css-cnuodh-DropDown, .select_mod, .popup-cat, .setting-modal, .styles_dropdown_items_wrapper__37z95, ._1KMXW, .ctrl_box, .bil-drop, .hector-landing__pricing--switch, .bil_modal, .bil_modal::before, .cs_drp, .cus_mod, .MuiPickersLayout-root.css-1g7nc1s-MuiPickersLayout-root, .coupen_body .table thead tr th, .my-tool table,
.MuiPaper-root.MuiPaper-elevation.MuiPickersPopper-paper{
  background-color: ${Theme[theme].themeactive} !important;
}
.css-z2hyoe-ItemComponent, .css-1fpwyqn-DropDown, .css-ruojsf, .css-19xmt6l, .css-1hf4miw, .react-dropdown-select {
  background-color: ${Theme[theme].themeactive} !important;
  background: ${Theme[theme].themeactive} !important;
}
.my-tool, .swal2-popup, .cat-brand, .tsk-inp, .dropdown.cat-select .selected-value input, .cat-options, ul.cat-option li, .cat-options .cat-head{
  color: ${Theme[theme].text} !important;
}
.themetxt {
  color: ${Theme[theme].activetxt} !important;
}
.themeicn{
  color: ${Theme[theme].themeicon} !important;
}
.themedefault, .dropdown .option.selected, .dropdown .option:hover, .click-animate:hover, .master_trans .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded,
.master_trans .MuiAccordionDetails-root, .themedefault.modal-header, .rules-creation-panel-column-or-condition-section .rules-creation-panel-column-condition-section .condition-compare-label {
  background-color: ${Theme[theme].themecrd} !important;
}
.themegray, .bg-blur, .circle, .bg-light, .styles_country_search__J15ie{
  background-color: ${Theme[theme].themelight} !important;
}
.data-tab thead th, .data-tab tbody tr:last-child td, .data-tab tbody tr:last-child th, .cat-table table th, .data-tab thead, .bg_light{
  background-color: ${Theme[theme].tablehead} !important;
  color: ${Theme[theme].themeicon} !important;
}
.form-group.check-box.cursor-move label{
  color: ${Theme[theme].themeicon} !important;
}
.data-tab tbody th, .rdrStaticRange, .rdrCalendarWrapper.rdrDateRangeWrapper, .rdrDateDisplayWrapper, table, .listings{
  background-color: ${Theme[theme].activeColor} !important;
  color: ${Theme[theme].tablesticky} !important;
}
.data-tab tbody td{
  background-color: ${Theme[theme].activeColor} !important;
  color: ${Theme[theme].tabledata} !important;
}
.select__control.select__control--is-focused.select__control--is-open.css-t2ipsp-control, .sep-head, .sep-title, .cat_rowfil:hover, ul.cat-option li:hover, .rdrDateDisplayItem input, .rdrNextPrevButton, .rdrStaticRangeLabel:hover, .styles_dropdown__kvYP8, .bil-drop li:hover, table.theme_table thead tr {
  background-color: ${Theme[theme].chartdrop} !important;
  color: ${Theme[theme].text} !important;
}
.pcoded-header .input-group .input-group-text, .pcoded-header a, .pcoded-header .btn, .pcoded-header.dropdown-toggle{
  color: ${Theme[theme].textblsh} !important;
}
.rdrDayDisabled, .form-group.check-box label:before{
  background-color: ${Theme[theme].disabledt} !important;
  // border: 1px solid ${Theme[theme].text} !important;
}

.form-group.check-box input:checked + label:after{
  border: solid ${Theme[theme].checkbg} !important;
  border-width: 0 2px 2px 0 !important;
}

.chart-head, .cat-head, .pcoded-header{
  background-color: ${Theme[theme].charthead} !important;
}

.rs-popover[class*=placement-bottom] > .rs-popover-arrow::after{
  border-bottom-color: ${Theme[theme].charthead} !important;
}
.dropdown.show:before{
  color: ${Theme[theme].charthead} !important;
}
.cat-table table .active-row, .data-tab .active-row th, .data-tab .active-row td, .rdrDefinedRangesWrapper .rdrStaticRangeSelected{
  background-color: ${Theme[theme].catactive} !important;
  color : ${Theme[theme].text} !important;
}
.pcoded-navbar.brand-default .header-logo, .pcoded-navbar[class*=navbar-].brand-default .header-logo, .navbar-content.datta-scroll, .new-theme-sidemenu .ps-sidebar-root.ps-collapsed .ps-submenu-content{
  background-color: ${Theme[theme].navbar} !important;
}

.dropdown-item:hover, .dropdown-item:focus, .select-account .dropdown-item.active, .select-account .dropdown-item:active{
  background-color: ${Theme[theme].charthead} !important;
}

.css-1nmdiq5-menu, .css-10wo9uf-option, .css-tr4s17-option{
  background-color: ${Theme[theme].themeactive} !important;
  color: ${Theme[theme].text} !important;
}
.select__option.select__option--is-focused.css-d7l1ni-option{
  background-color: ${Theme[theme].selcedopt} !important;
  color: ${Theme[theme].text} !important;
}
.pcoded-navbar .pcoded-inner-navbar > li.active > a, .pcoded-navbar .pcoded-inner-navbar > li.pcoded-trigger > a{
  background-color: ${Theme[theme].navbar} !important;
}
.pcoded-navbar .pcoded-submenu{
  background-color: ${Theme[theme].navbar} !important;
}
.tool-date{
  color: ${Theme[theme].tooldate} !important;
}
.filter-clr, .car_filtersec, input.cus_date-select.rs-input,.form-field.cus_date-select, .rdrMonthAndYearPickers .rdrMonthPicker, .rdrMonthAndYearPickers .rdrYearPicker{
  background-color: ${Theme[theme].catsec} !important;
  color: ${Theme[theme].text} !important;
}
.txt-theme, .ReactToast--title, .tab-col .nav-pills .nav-link.active, .tab-col .nav-pills .show > .nav-link{
  color: ${Theme[theme].linktxt} !important;
}
.ReactToast.success{
 border-left-color: ${Theme[theme].linktxt} !important;
}
.pcoded-header .dropdown .dropdown-toggle:after{
  border-color: ${Theme[theme].themeicon} !important;
}
.view-more{
  color: ${Theme[theme].themeblue} !important;
  border-color: ${Theme[theme].themeblue} !important;
}
.bg-overlay, .chart-overlay, .brand-table .notify_overlay{
  background-color: ${Theme[theme].overlay} !important;
}
.trig-list, .hyper_link{
  background-color: ${Theme[theme].trigcrd} !important;
  // color: ${Theme[theme].themeblue} !important;
}
.edit-tri, .del-tri{
  background-color: ${Theme[theme].edittri} !important;
}

.rdrPprevButton i{
  border-color: transparent ${Theme[theme].text} transparent transparent !important;
}
.rdrNextButton i{
  border-color: transparent transparent transparent ${Theme[theme].text};
}

.modal-header{
  background-color: ${Theme[theme].navbar} !important;
  color: white !important;
}
.select__option--is-selected, .add-crd{
  background-color: ${Theme[theme].selcedopt} !important;
  color: ${Theme[theme].text} !important;
}
.green-shde{
  background-color: ${Theme[theme].upstairs} !important;
}
.red-shde{
  background-color: ${Theme[theme].downstairs} !important;
}
.text-secondary{
  color: ${Theme[theme].secondary} !important;
}
.data-tab tbody tr:last-child th::before, .data-tab tbody tr:last-child th::after,
.data-tab tbody tr:last-child td::before, .data-tab tbody tr:last-child td::after,
.data-tab tbody th::before, .data-tab tbody th::after, .styles_country_search__J15ie, .context-menu-card{
  border: 1px solid ${Theme[theme].tabborder} !important;
}
.data-tab thead tr:nth-child(1) th::before, hr, .table td, .table th{
  border-top: 1px solid ${Theme[theme].tabborder} !important;
}
.data-tab thead tr:nth-child(1) th::after, .table thead th, .modal-header{
  border-bottom: 1px solid ${Theme[theme].tabborder} !important;
}
.data-tab tbody td{
  border-right: 1px solid ${Theme[theme].tabborder} !important;
}
.data-tab th, .data-tab td, .card .card-header, .pcoded-header .dropdown .notification .noti-head{
  border-bottom: 1px solid ${Theme[theme].tabborder} !important;
}
.data-tab .out-box, .form-control, .dropdown .selected-value input, .border, .sep-body, .main-btn, .tsk-inp{
  border: 1px solid ${Theme[theme].tabborder} !important;
}
.border-right, .border-bottom, .border-left, .border-top, .pcoded-header .dropdown .notification .noti-footer, .table-border, .cat-table table th, .cat-table table td, .trig-modal, .view-more,
.brand-centered, .form-field, .react-dropdown-select, .css-wmy1p7-ReactDropdownSelect, .css-z2hyoe-ItemComponent, .css-dvwtj0-DropDown, .cat-modal, .list-show ol li, .setting-modal, .css-13cymwt-control,.coupen_body .table tbody tr:nth-child(1) td, .coupen_body .table thead th::before, .coupen_body .table thead th::after,
.rules_table.table thead tr th::before, .rules_table.table thead tr th::after, .adacc_flag{
  border-color: ${Theme[theme].tabborder} !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root, .css-1bn53lx{
  border: 1px solid ${Theme[theme].dateborder} !important;
}
.popup-cat.popup-cat_full, .trig-modal, .brand-centered, .popup-cat, .filter-datatab, .cat-options, .dropdown-menu, .pcoded-header .dropdown .profile-notification, .cat-modal, .filter-chart, .box-shadow, .select_mod, .cardshadow, .setting-modal, .floating .item-drp, .styles_dropdown_items_wrapper__37z95,
.dual_layer .modal-content{
  box-shadow: ${Theme[theme].boxshadow} !important;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  color: white !important; 
}
.list-field{
  background-color: ${Theme[theme].viewinp} !important;
}
.scroller::-webkit-scrollbar-track{
  background: ${Theme[theme].tablehead} !important;
}
g.highcharts-grid.highcharts-yaxis-grid path{
  stroke: ${Theme[theme].tabborder} !important;
  stroke-dasharray: 10;
}
.themeactive{
  background-color: ${Theme[theme].navbar} !important;
  color: #fff !important;
}
[type=radio]:checked + label:after{
  background-color: #1877F2 !important;
}
[type=radio]:checked + label:before{
  border: 1px solid #1877F2 !important;
}
.pcoded-header a.profile_link{
  color: ${Theme[theme].proflnk} !important;
}
.table_grp{
  background: ${Theme[theme].tablegrp} !important;
}
.territary-btn{
  background: ${Theme[theme].territry} !important;
}
.table.dash_table thead tr{
  background: ${Theme[theme].dahtab} !important;
}
.progress_bar{
   background: ${Theme[theme].progressbar} !important;
}

#multiselectContainerReact .optionListContainer {
  background: ${Theme[theme].selcedopt} !important;
  background-color: ${Theme[theme].selcedopt} !important;
  color: ${Theme[theme].text} !important;
}
.multiSelectContainer li:hover, .chip{
  background: ${Theme[theme].linktxt} !important;
}

.styles_dropdown__kvYP8, .styles_dropdown_items_wrapper__37z95 {
  z-index: 1510;
}

.tab-col .nav-pills .nav-link.active, .tab-col .nav-pills .show > .nav-link{
  border-color: ${Theme[theme].linktxt} !important;
}
.filter_selected, .cls-btn, .trig-box .or_sec, .gray_btn_2, .cs_drp, .css-1p3m7a8-multiValue, .mapped-dropdown-body-button-group-cancel, .rules-creation-panel-selected-section-rule-status,
.rules-creation-panel-forms-cardbox ul li{
  background-color: ${Theme[theme].lightGray} !important;
  color: ${Theme[theme].text} !important;
}
.asin-table th, .asin_modal .modal-header, .addcat_modal .header, .target_table .table th, .collapse-bar, .collapse-bar.opend, .col_asin_tab th, .text_tab table tbody tr:hover, .col_cpc_tab th, .fil_tab, .key_tags, .mod_set .headr_set, .client_table .table thead tr,
.daypart-history-listing-table thead tr th, .new-theme .modal-header, .rules-creation-panel-listing-table thead tr th {
  background: ${Theme[theme].asinTable} !important;
  color: ${Theme[theme].headTxt} !important;
}
.fil_tag, .master_map_region .card_box ul li .sts_count, .daypart-history-listing-table tbody tr td, .rules-creation-panel-listing-table tbody tr td{
  color: ${Theme[theme].headTxt} !important;
}
.fil_tab{
  background: ${Theme[theme].asinTable} !important;
}
.checkbox .checkmark {
  background: ${Theme[theme].beforeCheck} !important;
}
// .rs-popover[class*=placement-left] > .rs-popover-arrow::after{
//   border-color: ${Theme[theme].beforeCheck} !important;
// }
.checkbox input:checked ~ .checkmark, .checkbox.indet_check input:checked ~ .checkmark{
  background: ${Theme[theme].afterCheck} !important;
}
.tab-col .nav-pills .nav-link, .text-mildgr, .txt-lght, .tool-date, ul.tool-tab li .lft-sd, .my-tool table .lft-sd, .f-949191{
  color: ${Theme[theme].tabLink} !important;
}
.txt_rgb{
  color: ${Theme[theme].rgbtxt} !important;
}

.c-252631{
  color: ${Theme[theme].grayTxt} !important;
}
.col_asin_tab th {
  background: ${Theme[theme].asinCol} !important;
}
.text-black, .text_tab table td, .target_table td, .outine_btn_gray, .tym_rule li, .performance-main-line-chart-header h2, .performance-heatmap-header h2,
.mapped-dropdown-body-list-select-field{
  color: ${Theme[theme].headTxt} !important;
}
.asin_success_sec{
  background: ${Theme[theme].succBlk} !important;
}
.text_tab table th{
  color: ${Theme[theme].t2e2c34} !important;
}
.text_tab .txt_tab_footer{
  background: ${Theme[theme].f3f3f4} !important;
  color: ${Theme[theme].f84818a} !important;
}
.f-333843, .sel_mod_lst div, .cs_drp, .cus_mod li, .master_trans .css-o4b71y-MuiAccordionSummary-content{
  color: ${Theme[theme].f333843} !important;
}
.f-84818A{
  color: ${Theme[theme].f84818a} !important;
}

tr.hover_icn.dragging {
  width: 100%;
  background:${Theme[theme].lightGray} !important;
  border: 1px solid ${Theme[theme].lightGray} !important
}

.dropping {
  background:${Theme[theme].selcedopt} !important ;
}

.target_table tfoot td{
  background: ${Theme[theme].tfoot} !important;
  color: ${Theme[theme].tfoottxt} !important;
}
.tar_tab .nav-pills .nav-link{
   color: ${Theme[theme].tfoottxt} !important;
}
.text-tiles{
  color: ${Theme[theme].tilestxt} !important;
}
.target_table td, .target_table .table th, .target_table tbody td::before,
.target_table tbody td::after,
.target_table thead th::before,
.target_table thead th::after,
.target_table tfoot td::before,
.target_table tfoot td::after, .fil_tab, .asin-table tbody tr td::before, .asin-table tbody tr td::after, .fsn-master-list th::before, .fsn-master-list th::after, .page-dropdown .react-dropdown-select-dropdown, .daypart-history-listing-table td::after, .daypart-history-listing-table td::before, .daypart-history-listing-table th::after, .daypart-history-listing-table th::before, .daypart-history-listing-table thead tr,
.rules-creation-panel-listing-table td::after, .rules-creation-panel-listing-table td::before, .rules-creation-panel-listing-table th::after, .rules-creation-panel-listing-table th::before{
  border-color: ${Theme[theme].tartab} !important;
}
.tar_tab .nav-pills, .bil_modal::before, .table-bordered td, .table-bordered th{
  border-color: ${Theme[theme].navbor} !important;
}
.tar_tab .nav-pills .nav-link.active, .tar_tab .nav-pills .show > .nav-link{
  border-color: ${Theme[theme].navbor} !important;
  border-bottom: 0px !important;
}
.tar_tab .nav-pills .nav-link:after{
  background: ${Theme[theme].navbor} !important;
}
.hov_theme:hover{
background: ${Theme[theme].hovetthm} !important;
}
.info_mod h3{
  color: ${Theme[theme].headTxt} !important;
}
.context-menu-item:hover, .prf_spc {
  background: ${Theme[theme].drphov} !important;
}
.prf_spc {
  color: ${Theme[theme].f0a60cb} !important;
}
.gray-staus {
  background-color: ${Theme[theme].bg_delta} !important;
  color: ${Theme[theme].txt_delta} !important;
}
.f-222E3E{
  color: ${Theme[theme].f222E3E} !important;
}
.f-344054, .f-344054 span, .MuiPickersLayout-root.css-1g7nc1s-MuiPickersLayout-root, button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root,
button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today.css-jgls56-MuiButtonBase-root-MuiPickersDay-root, .css-5oi4td, .css-9twsku, .css-6exafu, .css-15a0tn7{
  color: ${Theme[theme].f344054} !important;
}
.slct_drp li, .inp_slc_fld, .f-101828, .ord_gray_crd .header, .prefix-icon, .postfix-icon{
  color: ${Theme[theme].f101828} !important;
}
.slct_drp li:hover, .slct_drp li.active{
  background-color: ${Theme[theme].ff9fafb} !important;
}
.prf_table table th{
  color: ${Theme[theme].f181a1c} !important;
}
.f-7F56D9{
  color: ${Theme[theme].f7F56D9} !important;
}
.purple_two{
  background-color: ${Theme[theme].purbtnv2} !important;
}
.pro_foot .cncl-btn{
  background-color: ${Theme[theme].ff2f4f7} !important;
}
.f-202226{
  color: ${Theme[theme].f202226} !important;
}
.rules_table.table thead tr th, .f-000, .dayparting-header-tab[data-active=true] h3, .daypart-schedules-list li h2, .history-filter-tabs .nav-pills a, .daypart-schedules-chart-header ul li span, .rules-creation-panel-listing-table tbody tr td .col-title,
.rules-creation-panel-apply-to-section .filter-metric-dropdown .filter-select-check li span.ml-2, .rules-creation-panel-apply-to-campaign-section .filter-metric-dropdown .filter-select-check li span.ml-2{
  color: ${Theme[theme].black} !important;
}
.add_rule, .sld_tag, .cls_tag{
  background: ${Theme[theme].fe7e6e6} !important;
}
.rule_ad_crd{
  background: ${Theme[theme].lightGray} !important;
}

.f-101828, .account_tab .nav-link{
  color: ${Theme[theme].t101828} !important;
}
.f-667085, .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root, .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  color: ${Theme[theme].t667085} !important;
}
.blu_pad{
  background: ${Theme[theme].bf4f9ff} !important;
}
.bil-drop{
  color: ${Theme[theme].gtxt} !important;
}
.bil_table table thead tr{
  background: ${Theme[theme].biltab} !important;
  color: ${Theme[theme].t667085} !important;
}
.target_table .table tr.active-row td{
  background: ${Theme[theme].actrow} !important;
}
.hector-landing__pricing--card-item:first-of-type[data-showmore=false] .hector-landing__pricing--card-item-readmore-container, .hector-landing__pricing--card-item:last-of-type[data-showmore=false] .hector-landing__pricing--card-item-readmore-container{
  background: ${Theme[theme].grthem} !important;
}
.hector-landing__pricing--card-item-info-list-item[data-brands=true], .hector-landing__pricing--card-item-price-amount, .hector-landing__pricing--card-item-price-unit, .hector-landing__pricing--switch-item {
  color: ${Theme[theme].f141414} !important;
}
.hector-landing__pricing--card-item-header-badge{
  background: ${Theme[theme].fEFEAFD} !important;
  color: ${Theme[theme].f5932ea} !important;
}
.hector-landing__pricing--card-item-readmore-btn{
  color: ${Theme[theme].f5932ea} !important;
}
.bil_table table tbody td{
  color: ${Theme[theme].t667085} !important;
}
.grn_pad{
  background: ${Theme[theme].fecfdf3} !important;
  color: ${Theme[theme].f027a48} !important;
}
.fre_crd{
  background: ${Theme[theme].ff6f4ff} !important;
  color: ${Theme[theme].f344054} !important;
}
.pay_crd{
  background: ${Theme[theme].ffef0f0} !important;
  color: ${Theme[theme].fe1261a} !important;
}
.country_slct_drp li{
  color: ${Theme[theme].f101828} !important;
}
.hector-landing__pricing--card-item-info-list-item{
  color: ${Theme[theme].f797979} !important;
}
.country_slct_drp li:hover, .country_slct_drp li.active{
  background: ${Theme[theme].ff9fafb} !important;
}
.hector-landing__pricing--card-item-price-badge{
  background: ${Theme[theme].fe8008933} !important;
  color: ${Theme[theme].fe80089} !important;
}
.feature_crd{
  box-shadow: ${Theme[theme].bilshadow} !important;
}
.b-C9C9CB{
  background: ${Theme[theme].fc9c9cb} !important;
}
.ord_gray_crd{
  background: ${Theme[theme].grcrd} !important;
}
.bil_modal .bil-active{
  background: ${Theme[theme].bilact} !important;
}

.snapshot-card, .snapshot-revenue-card-single, .master-overview-main-card, .master-overview-product-report-overlay–date-table-container, .master-overview-inventory-card, .master-overview-inventory-chart  {
  box-shadow: 0px 4px 20px 0px ${Theme[theme].snapshot_card_box_shadow};
}
.snapshot-card-header-container, .snapshot-card-body-metrics, .trend-chart--filter-overlay, .snapshot-header, .pie-chart-container-header, .seller-sales-header, .master-overview-header, .trend-chart  {
  border-color: ${Theme[theme].trend_chart_border_color} !important;
}
.snapshot-card-body-metrics__divider, .trend-chart-header--date-filter__divider {
  background-color: ${Theme[theme].tabborder}
}

.master-overview-split--divider, .master-overview-metrics__divider  {
  background-color: ${Theme[theme].master_overview_divider}
}
.master-overview-metrics{
  border-color: ${Theme[theme].master_overview_divider}
}

.trend-chart, .master-overview-pie-chart-container-header {
  border-color: ${Theme[theme].trend_chart_border_color}
}
.snapshot-revenue-card-heading, .master-overview-metric-card-current-value h2, .custom-react-table--body-row-cell {
  color: ${Theme[theme].snapshot_revenue_heading} !important
}

.snapshot-total-spend-card-revenue {
  background-color: #bc91f8 !important
}

.key_tags{
  background: ${Theme[theme].fECF4FE} !important;
}
.key_serch_tag{
  color: ${Theme[theme].f737791} !important;
}
.info_toaster, .key_table table th {
  background: ${Theme[theme].feff5ff} !important;
}
.key_table table td{
  background: ${Theme[theme].fF6FAFF} !important;
}

.snapshot-revenue-card-title {
  color: ${Theme[theme].snapshot_revenue_title}  !important;
}

.snapshot-card-header, .master-overview-metric-card-previous-value {
  
  color: ${Theme[theme].snapshot_card_header_tab}
}
.snapshot-card-growth-status-high {
  background-color: ${Theme[theme].snapshot_card_growth_status_high_background};
  color:${Theme[theme].snapshot_card_growth_status_high_color};

}

.snapshot-card-growth-status-equal, .orange-staus {
  background-color: ${Theme[theme].snapshot_card_growth_status_equal_background};
  color:${Theme[theme].snapshot_card_growth_status_equal_color};
}

.snapshot-card-growth-status-low {
  background-color: ${Theme[theme].snapshot_card_growth_status_low_background};
  color:${Theme[theme].snapshot_card_growth_status_low_color};
}

.snapshot-card-growth-status-ideal, .trend-chart-header--date-filter-wrapper, .trend-chart--filter-overlay, .master-overview-product-report-row-card {
  background-color: ${Theme[theme].snapshot_card_growth_status_ideal_background};
  color:${Theme[theme].snapshot_card_growth_status_ideal_color};
}

.css-i4bv87-MuiSvgIcon-root, .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon,
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.css-1vooibu-MuiSvgIcon-root, .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel, .css-vubbuv, .css-10qkmc3, .css-1cw4hi4, .css-sldnni, .f-8F8F91{
  color:${Theme[theme].f8F8F91};
}

.snapshot-product-report {
  background-color: ${Theme[theme].snapshot_product_report_background};
}


.snapshot-product-report-table--body-row-cell {
  background-color: ${Theme[theme].snapshot_product_report_table__body_row_cell_background};
  color: ${Theme[theme].snapshot_product_report_table__body_row_cell_color};
}

.snapshot-product-report-table--header-row-cell, .snapshot-product-report-table--header-row, .snapshot-product-report-table--body-row, .snapshot-product-report-table--body-row-cell {
  border-color: ${Theme[theme].snapshot_product_report_table__header_row_cell_border} !important;
}

.snapshot-product-report-table--body-row-cell-prev-date {
  color: ${Theme[theme].snapshot_product_report_table__header_row_cell_prev_date_color};
}

.snapshot-product-report-table--body-row-cell-product {
  border: 1px solid ${Theme[theme].snapshot_product_report_table__header_row_cell_product};
}
.snapshot-card-growth-status-low-purple {
  color: ${Theme[theme].snapshot_card_growth_status_low_purple_color};
  background: ${Theme[theme].snapshot_card_growth_status_low_purple_background};
}

.date_pick_sov{
  box-shadow: ${Theme[theme].sovShadow};
}
.chart_cont, .bg-cht{
  box-shadow: ${Theme[theme].conshadow};
}
.f-7E7E7E, .date_pick_sov{
  color:${Theme[theme].f7E7E7E} !important;
}
.tog_date{
  background-color: ${Theme[theme].secbg} !important;
}
.custom-react-table--header-row-cell,  .custom-react-table--body-row-cell, .custom-react-table--footer-row-cell{
  border-color: ${Theme[theme].custom_react_table_cell_border} !important
}

.master-overview-product-report-row-card-main-list-item-title {
  color: ${Theme[theme].performance_screen_product_report_row_card_main_list_item_title}
}
.master-overview-inventory-card-title {
  color: ${Theme[theme].performance_screen_inventory_card_title}
}

.snapshot-card-growth-status-high-purple {
  color: ${Theme[theme].snapshot_card_growth_status_high_purple_color};
  background: ${Theme[theme].snapshot_card_growth_status_high_purple_background};
}

.snapshot-card-growth-status-low-purple {
  color: ${Theme[theme].snapshot_card_growth_status_low_purple_color};
  background: ${Theme[theme].snapshot_card_growth_status_low_purple_background};
}

.master-overview-inventory-card-value {
  color: ${Theme[theme].snapshot_revenue_heading}
}

.draggable-dropdown-checkbox {
  background-color: ${Theme[theme].draggable_dropdown_checkbox}
}

.trend-chart--filter-overlay-button-group-cancel {
  background-color: ${Theme[theme].cancel_btn} !important;
  color:${Theme[theme].snapshot_card_growth_status_ideal_color} !important;
}

.mapped-dropdown {
  border-color: ${Theme[theme].mapped_dropdown_border};
}

.mapped-dropdown-body, .mapped-dropdown-body-button-group, .custom-drop-body{
  background-color: ${Theme[theme].mapped_dropdown_body_bg} !important;
}

.gray-box-filter{
  background-color: ${Theme[theme].bgFCFCFC} !important;
}

.mapped-dropdown-body-title {
  background-color:  ${Theme[theme].mapped_dropdown_body_title} !important;

}

.mapped-dropdown-body-item > div {
  color: ${Theme[theme].mapped_dropdown_body_item} !important;
}
.product-toggle{
  color:${Theme[theme].tabclr} !important;
}
.f-303030, .listings, .donut_chrt table td, .prod_prg_tip{
  color:${Theme[theme].sovdrp} !important;
}
.prod_img, .performance-heatmap-main table tfoot td:nth-child(n+2) div{
  background-color: ${Theme[theme].prodcrd} !important;
}
.select-listings-1:hover,.select-listings-2:hover,.select-listings-3:hover, .prog_bar{
  background-color: ${Theme[theme].progbar} !important;
}
.f-223079{
  color:${Theme[theme].f223079} !important;
}
.f-4F4F4F{
  color:${Theme[theme].f4F4F4F} !important;
}
.slct_prod, .f-303030, .MuiStepLabel-label.MuiStepLabel-active,.MuiStepLabel-label.MuiStepLabel-completed,.c_tags{
  color:${Theme[theme].f303030} !important;
}

.slct_prod.active, .set_brand .css-1p3m7a8-multiValue{
  background-color: ${Theme[theme].b303030} !important;
}

.MuiStepLabel-label{
  color:${Theme[theme].f7E7E7E} !important;
}
.setting_stepper .MuiStepIcon-root{
  color:${Theme[theme].fD1D5DB} !important;
}
.slct_prod.active{
  box-shadow: ${Theme[theme].acttab} !important;
}
.f-8E8F96{
  color:${Theme[theme].f8E8F96} !important;
}
.theme_date_pcker .rdrNextPrevButton{
  background: ${Theme[theme].fF9F7FC};
}

.master-overview-product-report-product-title::after {
  background: ${Theme[theme].master_overview_product_report_product_title}
}
.snapshot-product-report-product-title::after {
  background: ${Theme[theme].snapshot_product_report_product_title}
}

.master_map_region .card_box, .master_map_region{
  border-color: ${Theme[theme].masterBorder};
}
.mastr-srch{
  border-color: ${Theme[theme].lightGray}; 
}
.green-staus{
  background-color: ${Theme[theme].greenStatusBg};
  color: ${Theme[theme].greenStatusTxt};
}
.red-staus{
  background-color: ${Theme[theme].redStatusBg};
  color: ${Theme[theme].redStatusTxt};
}
.f-10182880{
  color: ${Theme[theme].f10182880};
}

.settings_default_view, .drag-drop-box{
  background: ${Theme[theme].fF7F7F7BF}
}

.sp-circle-badge{
  background-color: ${Theme[theme].spBg} !important;
  color: ${Theme[theme].spText} !important;
}

.sb-circle-badge{
  background-color: ${Theme[theme].sbBg} !important;
  color: ${Theme[theme].sbText} !important;
}

.sd-circle-badge{
  background-color: ${Theme[theme].sdBg} !important;
  color: ${Theme[theme].sdText} !important;
}

.e-circle-badge{
  background-color: ${Theme[theme].eBg} !important;
  color: ${Theme[theme].eText} !important;
}

.p-circle-badge{
  background-color: ${Theme[theme].pBg} !important;
  color: ${Theme[theme].pText} !important;
}

.b-circle-badge{
  background-color: ${Theme[theme].bBg} !important;
  color: ${Theme[theme].bText} !important;
}

.performance-heatmap-main table td, .performance-heatmap-main table th{
  border-color: ${Theme[theme].themeColor} !important;
}

.heatmap-bulk-schedular-modal-gridbox-col p{
  color: ${Theme[theme].f5F6368CC} !important;
}

.daypart-schedular-custom-selection li{
  background-color: ${Theme[theme].grayTags} !important;
  color: ${Theme[theme].text} !important;
}

.highlighted-column{
  box-shadow: 0px 44px 35px 0px ${Theme[theme].heatmap_hover_shadow};
}

.heatmap-bulk-schedular-modal-settings-header{
  background-color: ${Theme[theme].bgA100BD1F} !important;
  color: ${Theme[theme].text} !important;
}

.heatmap-bulk-schedular-modal-settings{
  border-color: ${Theme[theme].boxBorder} !important;
}

.time-date-padge{
  background: ${Theme[theme].timeBadgeCard} !important;
  color: ${Theme[theme].timeBadgeText} !important;
}
.f-5F6368, .checkbox-metric-dropdown label, .heatmap-hour-list label, .filter-select-check label{
  color:${Theme[theme].f5F6368} !important;
}
.dayparting-header-tab{
  color:${Theme[theme].tabTextColour} !important;
}
.daypart-schedules-chart-metrics-card{
  border-color:${Theme[theme].metricsBorder} !important;
}
.daypart-schedules-tabs-bar.nav-tabs .nav-item.show .nav-link, .daypart-schedules-tabs-bar.nav-tabs .nav-link.active{
  background: ${Theme[theme].activeCustomTabs} !important;
  color: ${Theme[theme].black} !important;
}

.daypart-schedules-chart-header{
  background: ${Theme[theme].bgF6F2FA99} !important;
}

.daypart-schedules-chart-header ul li h2{
  color: ${Theme[theme].f000000B2} !important;
}

.daypart-schedules-chart-header ul li.border-box{
  border: 1px solid ${Theme[theme].dayPartBorder} !important;
}

.rules-creation-panel-column-header, .rules-creation-panel-column-or-condition-section,
.rules-creation-panel-column-or-condition-section .rules-creation-panel-column-condition-section.open-condition-header,
.rules-creation-panel-apply-to-section, .rules-creation-panel-apply-to-campaign-section-drop-header{
  background: ${Theme[theme].rulesHeader} !important;
}

.MuiButtonBase-root.MuiRadio-root{
  color: ${Theme[theme].text} !important;
}

.rules-creation-panel-listing-table td:nth-child(1), .rules-creation-panel-listing-table td:nth-child(2),
.rules-creation-panel-static-header .breadcrumb{
  background-color: ${Theme[theme].themeColor} !important;
}

.rules-creation-panel-listing-table tbody tr td .col-description{
  color: ${Theme[theme].descriptionText} !important;
}

.rules-creation-panel-column-or-condition-section .rules-creation-panel-column-condition-section .accordion-btn{
  border-color: ${Theme[theme].f000000B2} !important;
  color: ${Theme[theme].black} !important;
}

.rules-creation-panel-column-or-condition-section .rules-creation-panel-column-condition-section,
.rules-creation-panel-column-condition-collapse-section, .rules-creation-panel-fequency-column li,
.rules-creation-panel-action-column, .rules-creation-panel-column .condition-bulk-items::before,
.rules-creation-panel-column-or-condition-section > :nth-child(1)::before, .rules-creation-panel-apply-to-campaign-section{
  border-color: ${Theme[theme].flagBorder} !important;
}

.rules-creation-panel-column .condition-bulk-items .rules-creation-panel-column-last-and-card::before{
  border-color: ${Theme[theme].themeColor} !important;
}

.rules-creation-panel-forms-cardbox{
  border-color: ${Theme[theme].bE8E8E8} !important;
}
`;

export default Styles;
